import { Flex, Link, Text, Divider } from 'theme-ui';
import React from 'react';
import { GlobeIcon, TrashIcon } from '@heroicons/react/outline';
import { Photo } from 'tabler-icons-react';
import { CompanyUser, Company } from './types';
import { LoginAsAdmin } from './LoginAsAdmin';
import { useNavigate } from 'react-router-dom';
import { setCompanyId } from '@/store/deleteCompany';
import { useDispatch } from 'react-redux';
import { SyncMailchimpUsers } from './SyncMailchimpUsers';

const ActionsItems: React.FC<Company> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mailchimpEnabled = localStorage.getItem('mailchimp');

    return (
        <React.Fragment>
            <Flex
                sx={{
                    flexDirection: 'column',
                    gap: 3,
                }}
            >
                <LoginAsAdmin companyId={props.Id} mr={3} variant="menu" />
                <Link
                    variant="menu"
                    target="_blank"
                    href={`https://${props?.SitePath}.${process.env.REACT_APP_COMPANY_HOMEPAGE_DOMAIN_NAME}`}
                >
                    <GlobeIcon width={16} height={16} />
                    <Text>Navigate to website</Text>
                </Link>
                <Link
                    variant="menu"
                    target="_blank"
                    href={`/clients/${props.Id}`}
                    onClick={(evt) => {
                        evt.preventDefault();
                        navigate(`/clients/${props.Id}`);
                    }}
                >
                    <Photo width={16} height={16} />
                    <Text>Open details</Text>
                </Link>
                {mailchimpEnabled ? (
                    <SyncMailchimpUsers companyId={props.Id} variant="menu" />
                ) : null}
                <Link
                    variant="menu"
                    href={`#`}
                    color="danger"
                    onClick={(evt) => {
                        evt.preventDefault();
                        dispatch(setCompanyId(props.Id));
                    }}
                >
                    <TrashIcon width={16} height={16} color="danger" />
                    <Text color="danger">Delete</Text>
                </Link>
            </Flex>
            <Divider variant="styles.hr.menu" />
        </React.Fragment>
    );
};

export default ActionsItems;
