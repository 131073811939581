import { get, Theme } from 'theme-ui';
import { tailwind } from '@theme-ui/presets';
import tailwindColors from 'tailwindcss/colors';
import createTypographyPreset from '@tailwindcss/typography';
import { getTailwindFontSizes } from '@/theme/utils';

const typographyPreset = createTypographyPreset();

const fontSizes = getTailwindFontSizes(typographyPreset);

const space = [...tailwind.space];
space[1] = '4px';
space[2] = '8px';
space[3] = '10px';
space[4] = '32px';

const radii = { ...tailwind.radii };
radii.sm = '4px';
// @ts-ignore
radii.xsm = '3px';

// console.log(tailwindColors, tailwind.colors);

const colors = {
    ...tailwind.colors,
    gray: [...Object.values(tailwindColors.gray)],
    yellow: [...Object.values(tailwindColors.yellow)],
    teal: [...Object.values(tailwindColors.teal)],
    red: [...Object.values(tailwindColors.red)],
    purple: [...Object.values(tailwindColors.purple)],
    orange: [...Object.values(tailwindColors.orange)],
    indigo: [...Object.values(tailwindColors.indigo)],
    green: [...Object.values(tailwindColors.green)],
    blue: [...Object.values(tailwindColors.blue)],
    danger: Object.values(tailwindColors.red)[5],
};

const theme: Theme = {
    ...tailwind,
    useColorSchemeMediaQuery: true,
    fonts: {
        ...tailwind.fonts,
        body: `'Inter', 'Roboto', 'Oxygen', sans-serif`,
        heading: `'Inter', 'Roboto', 'Oxygen', sans-serif`,
        monospace: 'Menlo, monospace',
    },
    fontSizes,
    // @ts-ignore
    colors: {
        ...colors,
        text: 'gray.9',
        background: '#fff',
        primary: '#1D4ED8',
        shadow: 'rgba(0, 0, 0, 0.1)',
        modes: {
            dark: {
                background: '#000',
                text: '#fff',
            },
        },
    },
    text: {
        default: {
            fontFamily: 'body',
            color: 'gray.9',
        },
        heading: {
            mb: 3,
            color: 'gray.9',
        },
    },
    links: {
        default: {
            fontFamily: 'body',
        },
        menu: {
            textDecoration: 'none',
            color: 'gray.7',
            fontWeight: 500,
            display: 'flex',
            gap: 3,
            margin: 0,
            alignItems: 'center',
        },
        header: {
            textDecoration: 'none',
            color: 'gray.7',
            fontWeight: 500,
            background: 'background',
            p: 2,
            borderRadius: 'sm',
            gap: 3,
            margin: 0,
            alignItems: 'center',
        },
    },
    layout: {
        segment: {
            border: '1px solid',
            borderColor: 'gray.3',
            p: 3,
            borderRadius: 'sm',
        },
        'error-message': {
            border: '1px solid',
            borderColor: 'red.6',
            color: 'danger',
            px: 2,
            pb: 2,
            pt: 1,
            borderRadius: 'sm',
        },
    },
    badges: {
        circle: {
            borderRadius: '50%',
            width: '16px',
            minWidth: '16px',
            height: '16px',
            fontSize: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            marginTop: -1,
        },
        primary: {
            borderRadius: 'xsm',
            backgroundColor: 'gray.7',
        },
        active: {
            fontFamily: 'body',
            fontWeight: '400',
            color: 'green.7',
            backgroundColor: 'green.2',
            padding: '2px 10px 2px 10px',
        },
    },
    buttons: {
        plain: {
            lineHeight: 1,
            cursor: 'pointer',
            bg: 'background',
            display: 'flex',
            alignItems: 'center',
            color: (t: Theme) => `${t.colors?.text}`,
            gap: 2,
            svg: {
                color: (theme) => get(theme, 'colors.text'),
            },
        },
        plainWithShadow: {
            lineHeight: 1,
            cursor: 'pointer',
            bg: 'background',
            display: 'flex',
            alignItems: 'center',
            boxShadow: (theme) => get(theme, 'shadows.default'),
            color: (t: Theme) => `${t.colors?.text}`,
            gap: 2,
            svg: {
                color: (theme) => get(theme, 'colors.text'),
            },
        },
        minimal: {
            p: 1,
            display: 'flex',
            color: 'text',
            bg: 'transparent',
            cursor: 'pointer',
        },
        danger: {
            cursor: 'pointer',
            backgroundColor: 'red.5',
            ':hover': {
                backgroundColor: 'red.6',
            },
            ':disabled': {
                backgroundColor: 'red.3',
            },
        },
        secondary: {
            backgroundColor: 'transparent',
            color: 'gray.5',
            border: '1px solid',
            borderColor: 'gray.5',
            ':hover': {
                backgroundColor: 'gray.1',
            },
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            transition: 'all 0.2s',
            ':disabled': {
                filter: 'grayscale(0.8)',
            },
        },
        shadow: {
            default: {
                display: 'flex',
                color: 'text',
                bg: 'transparent',
                cursor: 'pointer',
                boxShadow: (theme) => get(theme, 'shadows.default'),
                transitionProperty: 'box-shadow color',
                transitionDuration: (theme) => get(theme, 'transitions.duration.100'),
                ':hover': {
                    boxShadow: (theme) => get(theme, 'shadows.lg'),
                },
                ':active': {
                    color: 'primary',
                },
            },
            subtle: {
                display: 'flex',
                p: 1,
                color: 'text',
                bg: 'transparent',
                cursor: 'pointer',
                boxShadow: (theme) => get(theme, 'shadows.none'),
                transitionProperty: 'box-shadow color',
                transitionDuration: (theme) => get(theme, 'transitions.duration.100'),
                ':hover': {
                    boxShadow: (theme) => get(theme, 'shadows.md'),
                },
                ':active': {
                    color: 'primary',
                },
            },
        },
    },

    shadows: {
        ...tailwind.shadows,
        down: '0 5px 5px -5px rgba(0, 0, 0, 0.1)',
        menu: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);',
    },
    sizes: {
        ...tailwind.sizes,
        navCollapsed: '56px',
        navExpanded: '240px',
    },
    space,
    radii,
    cards: {
        widget: {
            borderRadius: 'sm',
            backgroundColor: 'background',
            p: 2,
        },
    },
    forms: {
        select: {
            width: 'auto',
            pr: '4',
        },
        input: {
            text: 'gray.9',
            backgroundColor: 'background',
            borderColor: 'transparent',
            borderRadius: 'xsm',
            p: 2,
            mb: 2,
        },
        label: {
            fontFamily: 'body',
            mb: 1,
            alignItems: 'center',
        },
    },
    alerts: {
        danger: {
            color: 'red.6',
            fontFamily: 'body',
            background: 'transparent',
            p: 0,
        },
    },
    styles: {
        spinner: {
            danger: {
                color: 'white',
            },
        },
        table: {
            position: 'relative',
            overflowX: 'auto',
            margin: 0,
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'left',
            width: '100%',
            borderSpacing: 0,
            fontFamily: 'body',
            color: (t: Theme) => get(t, 'colors.gray.9'),
            borderRadius: 'sm',
            p: {
                m: 0,
            },
            pre: {
                mt: 2,
                mb: 0,
            },
            'tr:first-of-type td:first-of-type': {
                borderTopLeftRadius: 'sm',
            },
            'tr:first-of-type td:last-child': {
                borderTopRightRadius: 'sm',
            },
        },
        th: {
            zIndex: 1,
            color: (t: Theme) => get(t, 'colors.gray.9'),
            px: 2,
            pl: 3,
            top: 0,
            position: 'sticky',
            backgroundColor: 'gray.1',
            '&:first-of-type': {
                borderTopLeftRadius: 'sm',
                borderBottomLeftRadius: 'sm',
            },
            '&:last-of-type': {
                borderTopRightRadius: 'sm',
                borderBottomRightRadius: 'sm',
            },
        },
        //@ts-ignore
        tbody: {
            color: (t: Theme) => get(t, 'colors.gray.9'),
            'tr:last-of-type': {
                borderBottom: 0,
            },
            background: (t: Theme) => get(t, 'colors.background'),
        },
        thead: {
            color: (t: Theme) => get(t, 'colors.gray.9'),
            borderRadius: 'sm',
            th: {
                py: 2,
            },
        },
        td: {
            // border: (t: Theme) => `1px solid ${get(t, 'colors.background')}`,
            color: (t: Theme) => get(t, 'colors.gray.9'),
            py: 3,
            px: 2,
            borderBottom: 0,
        },
        tdgroup: {
            lineHeight: '24px',
            background: '#fafbfc',
            whiteSpace: 'nowrap',
            py: 3,
            fontWeight: 'bold',
            fontFamily: 'monospace',
            flexDirection: 'row',
            alignItems: 'center',
        },
        tr: {
            borderBottom: (t: Theme) => ` 1px solid  ${t.colors?.shadow}`,
        },
        hr: {
            color: (t: Theme) => get(t, 'colors.shadow'),
            menu: {
                color: (t: Theme) => get(t, 'colors.gray.2'),
            },
        },
    },
};
// console.log(theme);

export { theme };
