import { Link, Text, Spinner, LinkProps, Box, Checkbox, Label, Grid } from 'theme-ui';
import React, { useState } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';
import { useSyncMailchimpUsersMutation } from '@/store/apiService';
import { defaultSyncMailchimpOptions } from '@/store/mailchimp';

export interface Props extends LinkProps {
    companyId?: string;
}

export const SyncMailchimpUsers: React.FC<Props> = ({ companyId, ...rest }) => {
    const [sync, syncState] = useSyncMailchimpUsersMutation();
    const [deleteNonExisting, setDeleteNonExisting] = useState(
        defaultSyncMailchimpOptions.DeleteNonExisting
    );

    const toggleDeleteNonExisting = () => setDeleteNonExisting(!deleteNonExisting);

    return (
        <Grid gap={2}>
            <Link
                href={'#'}
                onClick={(evt) => {
                    evt.preventDefault();
                    sync({
                        MemberCompanyId: companyId,
                        DeleteNonExisting: deleteNonExisting,
                        Test: false,
                    });
                }}
                {...rest}
            >
                {syncState.isLoading ? <Spinner size={16} /> : null}
                {!syncState.isLoading ? <RefreshIcon width={16} height={16} /> : null}
                <Text>Sync Mailchimp Users</Text>
            </Link>
            <Box pl={3}>
                <Label>
                    <Checkbox checked={deleteNonExisting} onChange={toggleDeleteNonExisting} />
                    Delete non Existing
                </Label>
            </Box>
        </Grid>
    );
};
