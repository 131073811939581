import { configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { apiService, apiServiceListener } from '@/store/apiService';
import { deleteCompany } from '@/store/deleteCompany';

export type InitialState = StateFromReducersMapObject<typeof mainReducer>;

const mainReducer = {
    [apiService.reducerPath]: apiService.reducer,
    [deleteCompany.name]: deleteCompany.reducer,
};

export const createStore = (initialState?: InitialState) => {
    let store = configureStore({
        reducer: {
            [apiService.reducerPath]: apiService.reducer,
            [deleteCompany.name]: deleteCompany.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().prepend(apiService.middleware, apiServiceListener.middleware),
    });
    if (initialState) {
        store = configureStore({
            preloadedState: initialState,
            reducer: mainReducer,
            middleware: (getDefaultMiddleware) =>
                getDefaultMiddleware().prepend(apiService.middleware),
        });
    }

    return store;
};

export type Store = ReturnType<typeof createStore>;
export type ApplicationDispatch = Store['dispatch'];
export type ApplicationState = ReturnType<Store['getState']>;

export const useAppSelector: TypedUseSelectorHook<ApplicationState> = useSelector;
export const useAppDispatch: () => ApplicationDispatch = useDispatch;
