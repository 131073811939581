import { Card, Container, Grid } from 'theme-ui';
import React from 'react';
import { SyncAllCompaniesMailchimpUsers } from './SyncAllCompaniesMailchimpUsers';

interface Props {}

const FavoriteActions: React.FC<Props> = (props) => {
    const mailchimpEnabled = localStorage.getItem('mailchimp');

    return (
        <Container>
            {mailchimpEnabled ? (
                <Grid sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr) )' }}>
                    <Card variant="widget" p={3}>
                        <SyncAllCompaniesMailchimpUsers />
                    </Card>
                </Grid>
            ) : null}
        </Container>
    );
};

export default FavoriteActions;
