import { Spinner, Box, Checkbox, Label, Grid, Button, Heading } from 'theme-ui';
import React, { useState } from 'react';
import { useSyncMailchimpUsersMutation } from '@/store/apiService';
import { defaultSyncMailchimpOptions } from '@/store/mailchimp';

export const SyncAllCompaniesMailchimpUsers: React.FC = ({ ...rest }) => {
    const [sync, syncState] = useSyncMailchimpUsersMutation();
    const [deleteNonExisting, setDeleteNonExisting] = useState(
        defaultSyncMailchimpOptions.DeleteNonExisting
    );
    const [test, setTest] = useState(defaultSyncMailchimpOptions.Test);

    const toggleDeleteNonExisting = () => setDeleteNonExisting(!deleteNonExisting);
    const toggleTest = () => setTest(!test);

    return (
        <Grid gap={2}>
            <Heading>Sync all companies mailchimp users</Heading>
            <Box>
                <Label>
                    <Checkbox checked={test} onChange={toggleTest} />
                    Test
                </Label>
                <Label>
                    <Checkbox checked={deleteNonExisting} onChange={toggleDeleteNonExisting} />
                    Delete non Existing
                </Label>
            </Box>
            <Button
                disabled={syncState.isLoading}
                variant="icon"
                onClick={(evt) => {
                    evt.preventDefault();
                    sync({
                        Test: test,
                        DeleteNonExisting: deleteNonExisting,
                    });
                }}
            >
                {syncState.isLoading ? <Spinner color="white" size={16} /> : null}
                <span>Sync</span>
            </Button>
        </Grid>
    );
};
